<template>
  <header class="sticky-header">
    <nav class="navbar navbar-expand-lg navbar-light bg-gray">
      <div class="container">
        <router-link class="navbar-brand" :to="{ name: 'Home' }">{{ t('appName') }}</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item" v-if="isLoggedIn">
              <router-link class="nav-link" :to="{ name: 'Home' }">{{ t('home') }}</router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <router-link class="nav-link" :to="{ name: 'MenuCreator' }">{{ t('menuCreator') }}</router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <router-link class="nav-link" :to="{ name: 'MenuHistory' }">{{ t('menuHistory') }}</router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn && isAdmin">
              <router-link class="nav-link" :to="{ name: 'AdminPanel' }">{{ t('adminPanel') }}</router-link>
            </li>

            <li class="nav-item" v-if="!isLoggedIn">
              <router-link class="nav-link" :to="{ name: 'Login' }">{{ t('login') }}</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" ref="dropdownToggle">
                {{ currentLanguageName }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="languageDropdown">
                <li><a class="dropdown-item" @click="changeLanguage('en')" href="#">English</a></li>
                <li><a class="dropdown-item" @click="changeLanguage('bg')" href="#">Български</a></li>
              </ul>
            </li>
            <li class="nav-item" v-if="isLoggedIn && tokenBalance !== null">
              <span class="nav-link">{{ t('tokens') }}: {{ tokenBalance }}</span>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <router-link class="nav-link" :to="{ name: 'Account' }">{{ t('account') }}</router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <a class="nav-link" @click="logout" href="#">{{ t('logout') }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { ref, onMounted, watch, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import axios from '../api/axios'
import { Dropdown } from 'bootstrap'

export default {
  name: 'AppHeader',
  setup() {
    const { t, locale } = useI18n()
    const router = useRouter()
    const route = useRoute()

    const isLoggedIn = ref(false)
    const isAdmin = ref(false)
    const currentLanguageName = ref('English')
    const dropdownToggle = ref(null)
    const tokenBalance = ref(null)
    let tokenRefreshInterval

    const checkLoginStatus = () => {
      isLoggedIn.value = !!localStorage.getItem('token')
      isAdmin.value = localStorage.getItem('isAdmin') === 'true'
    }

    const logout = () => {
      localStorage.removeItem('token')
      localStorage.removeItem('isAdmin')
      isLoggedIn.value = false
      isAdmin.value = false
      router.push({ name: 'Login' })
    }

    const changeLanguage = (lang) => {
      locale.value = lang
      localStorage.setItem('language', lang)
      currentLanguageName.value = getLanguageName(lang)
    }
    
    const setInitialLanguage = () => {
      const lang = localStorage.getItem('language') || 'en'
      changeLanguage(lang)
    }

    const getLanguageName = (lang) => {
      const languages = { en: 'English', bg: 'Български' }
      return languages[lang] || 'English'
    }

    const fetchTokenBalance = async () => {
      if (isLoggedIn.value) {
        try {
          const response = await axios.get('/api/user/tokens', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
          tokenBalance.value = response.data.tokens
        } catch (error) {
          console.error('Error fetching token balance:', error)
          tokenBalance.value = null
        }
      } else {
        tokenBalance.value = null
      }
    }

    onMounted(() => {
      checkLoginStatus()
      setInitialLanguage()
      fetchTokenBalance()
      // Initialize the dropdown
      if (dropdownToggle.value) {
        new Dropdown(dropdownToggle.value)
      }
      tokenRefreshInterval = setInterval(fetchTokenBalance, 10000) // Refresh every 30 seconds
    })

    onUnmounted(() => {
      clearInterval(tokenRefreshInterval)
    })

    watch(() => route.path, () => {
      checkLoginStatus()
      fetchTokenBalance()
    })

    return {
      t,
      isLoggedIn,
      isAdmin,
      currentLanguageName,
      logout,
      changeLanguage,
      dropdownToggle,
      tokenBalance
    }
  }
}
</script>

<style scoped>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.navbar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #343a40; /* Dark gray background */
}

.navbar-light .navbar-brand,
.navbar-light .nav-link {
  color: #f8f9fa; /* Light text color */
}

.navbar-light .nav-link:hover {
  color: #ffffff !important; /* Brighter white on hover */
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(248, 249, 250, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.dropdown-menu {
  background-color: #343a40;
  border-color: #454d55;
}

.dropdown-item {
  color: #f8f9fa;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #454d55;
  color: #ffffff;
}

.navbar-light .nav-link {
  color: #f8f9fa !important; /* Light text color */
  text-decoration: none; /* Remove underline */
}

.navbar-light .nav-link:hover {
  color: #ffffff !important; /* Brighter white on hover */
}
</style>