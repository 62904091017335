<template>
    <div class="create-menu">
      <h2 class="mb-4">{{ $t('createMenu') }}</h2>
      <form @submit.prevent="createMenu" class="needs-validation" novalidate>
        <div class="mb-3">
          <label for="dishName" class="form-label">{{ $t('dishName') }}</label>
          <input v-model="dishNameBg" type="text" class="form-control" id="dishName" required>
        </div>
        <div class="mb-3">
          <label for="description" class="form-label">{{ $t('description') }}</label>
          <textarea v-model="descriptionBg" class="form-control" id="description" rows="3" required></textarea>
        </div>
        <button type="submit" class="btn btn-primary">{{ $t('createMenu') }}</button>
      </form>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";



export default {
  data() {
    return {
      dishNameBg: '',
      descriptionBg: ''
    }
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  methods: {
    async createMenu() {
      const menuData = {
        dish_name_bg: this.dishNameBg,
        description_bg: this.descriptionBg
      }
      try {
        await this.$http.post('/menus', menuData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        this.toast.success('Menu created successfully!')
        this.$router.push('/')
      } catch (error) {
        this.toast.error('Failed to create menu')
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
.create-menu {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
</style>
