<template>
  <div class="account-page">
    <h1><i class="fas fa-user-circle"></i> {{ $t('account') }}</h1>
    <LoaderMain v-if="isLoading" />
    <div v-else-if="user" v-auto-animate>
      <div class="info-box">
        <p><strong><i class="fas fa-envelope"></i> Email:</strong> {{ user.email }}</p>
        <p><strong><i class="fas fa-user"></i> Username:</strong> {{ user.username }}</p>
      </div>
      
      <div class="form-box">
        <h2><i class="fas fa-envelope"></i> {{ $t('changeEmail') }}</h2>
        <form @submit.prevent="changeEmail">
          <div class="input-group">
            <label for="newEmail">{{ $t('newEmail') }}:</label>
            <input type="email" id="newEmail" v-model="newEmail" required>
          </div>
          <button type="submit"><i class="fas fa-save"></i> {{ $t('changeEmail') }}</button>
        </form>
      </div>

      <div class="form-box">
        <h2><i class="fas fa-lock"></i> {{ $t('changePassword') }}</h2>
        <form @submit.prevent="changePassword">
          <div class="input-group">
            <label for="currentPassword">{{ $t('currentPassword') }}:</label>
            <input type="password" id="currentPassword" v-model="currentPassword" required>
          </div>
          <div class="input-group">
            <label for="newPassword">{{ $t('newPassword') }}:</label>
            <input type="password" id="newPassword" v-model="newPassword" required>
          </div>
          <div class="input-group">
            <label for="confirmPassword">{{ $t('confirmPassword') }}:</label>
            <input type="password" id="confirmPassword" v-model="confirmPassword" required>
          </div>
          <button type="submit"><i class="fas fa-key"></i> {{ $t('changePassword') }}</button>
        </form>
      </div>
      <p v-if="message" :class="{ 'error': isError, 'success': !isError }" v-auto-animate>
        <i :class="isError ? 'fas fa-exclamation-circle' : 'fas fa-check-circle'"></i>
        {{ message }}
      </p>
    </div>
    <p v-else class="error">Unable to load user information. Please try again later.</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import api from '../api/axios'
import LoaderMain from './Loader.vue'

const user = ref(null)
const currentPassword = ref('')
const newPassword = ref('')
const confirmPassword = ref('')
const newEmail = ref('')
const message = ref('')
const isError = ref(false)
const isLoading = ref(true)


onMounted(async () => {
  try {
    const response = await api.get('/api/user/account')
    user.value = response.data
  } catch (error) {
    console.error('Error fetching user data:', error)
  } finally {
    isLoading.value = false
  }
})

const changePassword = async () => {
  if (newPassword.value !== confirmPassword.value) {
    message.value = 'New passwords do not match'
    isError.value = true
    return
  }

  isLoading.value = true
  try {
    const response = await api.post('/api/user/change-password', {
      currentPassword: currentPassword.value,
      newPassword: newPassword.value
    })
    message.value = response.data.message || 'Password changed successfully'
    isError.value = false
    currentPassword.value = ''
    newPassword.value = ''
    confirmPassword.value = ''
  } catch (error) {
    message.value = error.response?.data?.message || 'Error changing password'
    isError.value = true
  } finally {
    isLoading.value = false
  }
}

const changeEmail = async () => {
  isLoading.value = true
  try {
    const response = await api.post('/api/user/change-email', {
      newEmail: newEmail.value
    })
    user.value.email = newEmail.value
    message.value = response.data.message || 'Email changed successfully'
    isError.value = false
    newEmail.value = ''
  } catch (error) {
    message.value = error.response?.data?.message || 'Error changing email'
    isError.value = true
  } finally {
    isLoading.value = false
  }
}
</script>

<style scoped>
.account-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1, h2 {
  color: #333;
  margin-bottom: 20px;
}

.info-box, .form-box {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

.error {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.success {
  color: #388e3c;
  background-color: #e8f5e9;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}
</style>