<template>
  <div class="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="confirmationModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmationModalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
          <div v-if="showInput" class="mb-3">
            <label :for="inputId" class="form-label">{{ inputLabel }}</label>
            <input :type="inputType" class="form-control" :id="inputId" v-model="inputValue">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ cancelButtonText }}</button>
          <button type="button" :class="confirmButtonClass" @click="confirm">{{ confirmButtonText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { Modal } from 'bootstrap'

export default {
  name: 'ConfirmationModal',
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    showInput: {
      type: Boolean,
      default: false
    },
    inputLabel: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: 'text'
    },
    inputId: {
      type: String,
      default: 'modalInput'
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    },
    confirmButtonClass: {
      type: String,
      default: 'btn btn-primary'
    }
  },
  emits: ['confirm'],
  setup(props, { emit }) {
    const modal = ref(null)
    const inputValue = ref('')

    watch(() => props.showInput, (newValue) => {
      if (!newValue) {
        inputValue.value = ''
      }
    })

    const show = () => {
      if (!modal.value) {
        modal.value = new Modal(document.getElementById('confirmationModal'))
      }
      modal.value.show()
    }

    const hide = () => {
      if (modal.value) {
        modal.value.hide()
      }
    }

    const confirm = () => {
      emit('confirm', props.showInput ? inputValue.value : true)
      hide()
    }

    return {
      inputValue,
      show,
      hide,
      confirm
    }
  }
}
</script>