<template>
  <div class="translate-menu container-fluid">
    <h2>{{ t('translateMenu') }}</h2>
    <div v-if="!isTranslating && !isTranslated" class="text-center my-5">
      <button @click="translateMenu" class="btn btn-primary btn-lg">
        {{ t('translate') }}
      </button>
    </div>
    <div v-if="isTranslating" class="text-center my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ t('loading') }}</span>
      </div>
      <p class="mt-3">{{ t('translating') }}</p>
    </div>
    <div v-if="isTranslated" class="row">
      <div class="col-md-6">
        <h3>{{ t('bulgarianMenu') }}</h3>
        <div v-html="bulgarianMenuHTML"></div>
      </div>
      <div class="col-md-6">
        <h3>{{ t('englishMenu') }}</h3>
        <div v-html="englishMenuHTML"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import Quill from 'quill'
import axios from 'axios'

export default {
  name: 'TranslateMenu',
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const bulgarianMenuHTML = ref('')
    const englishMenuHTML = ref('')
    const menuData = ref([])
    const isTranslating = ref(false)
    const isTranslated = ref(false)

    onMounted(() => {
      if (route.query.menuData) {
        menuData.value = JSON.parse(decodeURIComponent(route.query.menuData))
        renderBulgarianMenu()
      }
    })

    const renderBulgarianMenu = () => {
      const quill = new Quill(document.createElement('div'))
      let content = []
      menuData.value.forEach((category) => {
        if (category.visible) {
          content.push({ insert: `${t(category.name)}\n`, attributes: { header: 2 } })
          if (category.dishes.length > 0) {
            category.dishes.forEach(dish => {
              content.push({ insert: `• ${dish}\n` })
            })
          } else {
            content.push({ insert: `${t('noDishes')}\n` })
          }
          content.push({ insert: '\n' })
        }
      })
      quill.setContents(content)
      bulgarianMenuHTML.value = quill.root.innerHTML
    }

    const translateMenu = async () => {
      isTranslating.value = true
      try {
        const translatedDishes = await Promise.all(
          menuData.value.flatMap(category => 
            category.dishes.map(dish => translateDish(dish))
          )
        )

        let dishIndex = 0
        const translatedMenuData = menuData.value.map(category => ({
          ...category,
          dishes: category.dishes.map(() => translatedDishes[dishIndex++])
        }))

        renderEnglishMenu(translatedMenuData)
        isTranslated.value = true
      } catch (error) {
        console.error('Translation error:', error)
        // Handle error (e.g., show error message to user)
      } finally {
        isTranslating.value = false
      }
    }

    const translateDish = async (dish) => {
      // Replace this with your actual API call to Claude or another translation service
      const response = await axios.post('/api/translate', { text: dish, targetLanguage: 'en' })
      return response.data.translatedText
    }

    const renderEnglishMenu = (translatedMenuData) => {
      const quill = new Quill(document.createElement('div'))
      let content = []
      translatedMenuData.forEach((category) => {
        if (category.visible) {
          content.push({ insert: `${t(category.name)}\n`, attributes: { header: 2 } })
          if (category.dishes.length > 0) {
            category.dishes.forEach(dish => {
              content.push({ insert: `• ${dish}\n` })
            })
          } else {
            content.push({ insert: `${t('noDishes')}\n` })
          }
          content.push({ insert: '\n' })
        }
      })
      quill.setContents(content)
      englishMenuHTML.value = quill.root.innerHTML
    }

    return {
      t,
      bulgarianMenuHTML,
      englishMenuHTML,
      isTranslating,
      isTranslated,
      translateMenu
    }
  }
}
</script>

<style scoped>
.translate-menu {
  padding: 20px;
}
</style>
