<template>
  <div>
    <!-- Remove the AppHeader from here -->
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h2 class="card-title text-center mb-4">{{ $t('login') }}</h2>
              <form @submit.prevent="loginUser">
                <div class="mb-3">
                  <label for="identifier" class="form-label">{{ $t('usernameOrEmail') }}:</label>
                  <input id="identifier" v-model="identifier" class="form-control" required />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">{{ $t('password') }}:</label>
                  <input id="password" type="password" v-model="password" class="form-control" required />
                </div>
                <button type="submit" class="btn btn-primary w-100">{{ $t('login') }}</button>
              </form>
              <p class="text-center mt-3">
                {{ $t('noAccount') }} 
                <router-link to="/register">{{ $t('registerHere') }}</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from '../api/axios';  // Import the axios instance

export default {
  name: 'LoginForm',
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      identifier: '',
      password: ''
    }
  },
  methods: {
    async loginUser() {
      try {
        const response = await axios.post('/api/login', {
          identifier: this.identifier,
          password: this.password
        })
        localStorage.setItem('token', response.data.access_token)
        localStorage.setItem('isAdmin', response.data.is_admin)
        this.toast.success(this.$t('loginSuccess'));
        this.$router.push('/')
      } catch (error) {
        this.toast.error(this.$t('loginFailed'));
      }
    }
  }
}
</script>