<template>
  <div class="menu-creator container-fluid">
    <div class="row mb-3">
      <div class="col-12 text-end">
        <router-link :to="{ name: 'MenuPreview', query: { menuData: encodedMenuData } }" class="btn btn-primary">
          <Icon icon="mdi:eye" /> {{ t('previewMenu') }}
        </router-link>
      </div>
    </div>
    <div class="row">
      <!-- Left side: Menu Editor -->
      <div class="col-md-6 editor-section">
        <h2>{{ t('menuEditor') }}</h2>
        
        <!-- Fixed Categories -->
        <div v-for="(category, index) in categories" :key="index" class="category-section mb-3">
          <h3>
            {{ t(category.name) }}
            <button @click="toggleCategory(index)" class="btn btn-sm btn-outline-secondary ms-2">
              <Icon :icon="category.visible ? 'mdi:eye-off' : 'mdi:eye'" />
              {{ category.visible ? t('hide') : t('show') }}
            </button>
          </h3>
          
          <!-- Add Dish -->
          <div class="mb-2">
            <input v-model="newDishes[index].name" class="form-control mb-2" :placeholder="t('newDish')">
            <input v-model="newDishes[index].price" type="number" step="0.01" class="form-control mb-2" :placeholder="t('price')">
            <input v-model="newDishes[index].description" class="form-control mb-2" :placeholder="t('description')">
            <select v-model="newDishes[index].allergens" multiple class="form-control mb-2">
              <option v-for="allergen in allergensList" :key="allergen" :value="allergen">{{ t(allergen) }}</option>
            </select>
            <button @click="addDish(index)" class="btn btn-primary mt-2">
              <Icon icon="mdi:plus" /> {{ t('addDish') }}
            </button>
          </div>

          <!-- Dish List -->
          <ul class="list-group" v-auto-animate>
            <li v-for="(dish, dishIndex) in category.dishes" :key="dish.id" class="list-group-item">
              <div v-if="editingDish.id === dish.id">
                <input v-model="editingDish.name" class="form-control mb-2" :placeholder="t('dishName')">
                <input v-model="editingDish.price" type="number" step="0.01" class="form-control mb-2" :placeholder="t('price')">
                <input v-model="editingDish.description" class="form-control mb-2" :placeholder="t('description')">
                <select v-model="editingDish.allergens" multiple class="form-control mb-2">
                  <option v-for="allergen in allergensList" :key="allergen" :value="allergen">{{ t(allergen) }}</option>
                </select>
                <button @click="saveDishEdit(index, dishIndex)" class="btn btn-sm btn-success me-2">
                  <Icon icon="mdi:content-save" /> {{ t('save') }}
                </button>
                <button @click="cancelDishEdit" class="btn btn-sm btn-secondary">
                  <Icon icon="mdi:cancel" /> {{ t('cancel') }}
                </button>
              </div>
              <div v-else>
                <strong>{{ dish.name }}</strong> - {{ dish.price }} BGN
                <p v-if="dish.description">{{ dish.description }}</p>
                <p v-if="dish.allergens.length">{{ t('allergens') }}: {{ dish.allergens.join(', ') }}</p>
                <button @click="editDish(dish)" class="btn btn-sm btn-primary float-end ms-2">
                  <Icon icon="mdi:pencil" /> {{ t('edit') }}
                </button>
                <button @click="removeDish(index, dishIndex)" class="btn btn-sm btn-danger float-end">
                  <Icon icon="mdi:delete" /> {{ t('remove') }}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- Right side: Live Preview -->
      <div class="col-md-6 preview-section">
        <h2>{{ t('menuPreview') }}</h2>
        <div ref="quillEditor"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'
import { Icon } from '@iconify/vue'
import { useStore } from 'vuex'

export default {
  components: {
    Icon
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const quillEditor = ref(null)
    const store = useStore()
    let quill = null

    const categories = reactive([
      { name: 'starters', dishes: [], visible: true },
      { name: 'mainCourses', dishes: [], visible: true },
      { name: 'desserts', dishes: [], visible: true },
      { name: 'drinks', dishes: [], visible: true }
    ])

    const newDishes = reactive(categories.map(() => ({
      name: '',
      price: '',
      description: '',
      allergens: []
    })))

    const allergensList = [
      'gluten', 'crustaceans', 'eggs', 'fish', 'peanuts', 'soybeans', 'milk', 'nuts', 
      'celery', 'mustard', 'sesame', 'sulphites', 'lupin', 'molluscs'
    ]

    const editingDish = reactive({
      id: null,
      name: '',
      price: '',
      description: '',
      allergens: []
    })

    let nextDishId = 1

    const encodedMenuData = computed(() => {
      return encodeURIComponent(JSON.stringify(categories))
    })

    onMounted(() => {
      if (route.query.menuData) {
  const savedData = JSON.parse(decodeURIComponent(route.query.menuData))
  if (savedData.categories) {
    Object.assign(categories, savedData.categories)
  }
  if (savedData.quillContent) {
    store.commit('setMenuContent', savedData.quillContent)
  }
  // Remove the query parameter from the URL
  router.replace({ name: 'MenuCreator' })
}

      nextTick(() => {
    initQuill()
    const storedContent = store.state.menuContent
    if (storedContent) {
      quill.setContents(storedContent)
    } else if (route.query.menuData) {
      const savedMenuData = JSON.parse(decodeURIComponent(route.query.menuData))
      Object.assign(categories, savedMenuData)
      updateQuillContent(categories)
      // Remove the query parameter from the URL
      router.replace({ name: 'MenuCreator' })
    } else {
      updateQuillContent(categories)
    }
  })
})

    const initQuill = () => {
      if (quill) return

      quill = new Quill(quillEditor.value, {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
          ]
        }
      })

      quill.on('text-change', () => {
        const content = quill.getContents()
        store.commit('setMenuContent', content)
      })

      console.log('Quill initialized:', quill)
    }

    const updateQuillContent = (categories) => {
      if (!quill) return

      let content = []
      categories.forEach((category) => {
        if (category.visible) {
          content.push({ insert: `${t(category.name)}\n`, attributes: { header: 2 } })
          if (category.dishes.length > 0) {
            category.dishes.forEach(dish => {
              content.push({ insert: `• ${dish.name} - ${dish.price} BGN\n` })
              if (dish.description) {
                content.push({ insert: `  ${dish.description}\n` })
              }
              if (dish.allergens.length) {
                content.push({ insert: `  ${t('allergens')}: ${dish.allergens.join(', ')}\n` })
              }
            })
          } else {
            content.push({ insert: `${t('noDishes')}\n` })
          }
          content.push({ insert: '\n' })
        }
      })
      quill.setContents(content)
      store.commit('setMenuContent', quill.getContents())
    }

    const toggleCategory = (index) => {
      categories[index].visible = !categories[index].visible
      updateQuillContent(categories)
    }

    const addDish = (categoryIndex) => {
      const newDish = newDishes[categoryIndex]
      if (newDish.name && newDish.price) {
        categories[categoryIndex].dishes.push({
          id: nextDishId++,
          name: newDish.name,
          price: parseFloat(newDish.price),
          description: newDish.description,
          allergens: newDish.allergens
        })
        newDishes[categoryIndex] = { name: '', price: '', description: '', allergens: [] }
        updateQuillContent(categories)
      }
    }

    const removeDish = (categoryIndex, dishIndex) => {
      categories[categoryIndex].dishes.splice(dishIndex, 1)
      updateQuillContent(categories)
    }

    const editDish = (dish) => {
      editingDish.id = dish.id
      editingDish.name = dish.name
      editingDish.price = dish.price
      editingDish.description = dish.description
      editingDish.allergens = [...dish.allergens]
    }

    const saveDishEdit = (categoryIndex, dishIndex) => {
      const dish = categories[categoryIndex].dishes[dishIndex]
      dish.name = editingDish.name
      dish.price = parseFloat(editingDish.price)
      dish.description = editingDish.description
      dish.allergens = editingDish.allergens
      cancelDishEdit()
      updateQuillContent(categories)
    }

    const cancelDishEdit = () => {
      editingDish.id = null
      editingDish.name = ''
      editingDish.price = ''
      editingDish.description = ''
      editingDish.allergens = []
    }

    return {
      quillEditor,
      updateQuillContent,
      t,
      encodedMenuData,
      categories,
      newDishes,
      allergensList,
      editingDish,
      toggleCategory,
      addDish,
      removeDish,
      editDish,
      saveDishEdit,
      cancelDishEdit
    }
  }
}
</script>

<style scoped>
.menu-creator {
  padding: 20px;
}
.editor-section, .preview-section {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding: 20px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}
#quill-editor {
  height: calc(100% - 60px);
  margin-top: 20px;
}

.btn .iconify {
  vertical-align: -0.125em;
  margin-right: 0.25rem;
}
</style>