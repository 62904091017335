<template>
    <div class="home">
      <section class="hero" v-auto-animate>
        <h1>{{ t('welcomeTitle') }}</h1>
        <p>{{ t('welcomeDescription') }}</p>
        <router-link :to="getStartedRoute" class="btn btn-primary">
          {{ t('getStarted') }}
        </router-link>
      </section>
  
      <section class="features" v-auto-animate>
        <h2>{{ t('featuresTitle') }}</h2>
        <div class="feature-list" v-auto-animate>
          <div class="feature" v-for="feature in features" :key="feature.title">
            <i :class="feature.icon"></i>
            <h3>{{ t(feature.title) }}</h3>
            <p>{{ t(feature.description) }}</p>
          </div>
        </div>
      </section>
  
      <section class="how-it-works" v-auto-animate>
        <h2>{{ t('howItWorksTitle') }}</h2>
        <div class="steps">
          <div class="step" v-for="(step, index) in howItWorksSteps" :key="index">
            <div class="step-content">
              <div class="image-container">
                <img :src="getStepImage(step.image)" :alt="t(step.title)" class="step-image">
              </div>
              <h3>{{ t(step.title) }}</h3>
              <p>{{ t(step.description) }}</p>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Add more sections as needed -->
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  import { computed } from 'vue'

  export default {
    name: 'HomePage',  // Changed from 'Home' to 'HomePage'
    setup() {
      const { t, locale } = useI18n()

      const features = [
        { icon: 'fas fa-utensils', title: 'feature1Title', description: 'feature1Description' },
        { icon: 'fas fa-magic', title: 'feature2Title', description: 'feature2Description' },
        { icon: 'fas fa-history', title: 'feature3Title', description: 'feature3Description' },
      ]

      const howItWorksSteps = [
        { image: 'step1', title: 'step1Title', description: 'step1Description' },
        { image: 'step2', title: 'step2Title', description: 'step2Description' },
        { image: 'step3', title: 'step3Title', description: 'step3Description' },
      ]

      const isLoggedIn = computed(() => {
        return !!localStorage.getItem('token')
      })

      const getStartedRoute = computed(() => {
        return isLoggedIn.value ? { name: 'MenuCreator' } : { name: 'Login' }
      })

      const getStepImage = (imageName) => {
        return `/images/${imageName}_${locale.value}.png`
      }

      return { t, features, howItWorksSteps, getStartedRoute, getStepImage }
    }
  }
  </script>
  
  <style scoped>
  .home {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .hero {
    text-align: center;
    margin-bottom: 4rem;
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .hero h1 {
    font-size: 3rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .hero p {
    font-size: 1.2rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto 2rem;
  }
  
  .btn-primary {
    background-color: #4CAF50;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #45a049;
  }
  
  .features {
    margin-bottom: 4rem;
  }
  
  .features h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .feature-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature {
    text-align: center;
    max-width: 300px;
    margin: 1rem;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .feature:hover {
    transform: translateY(-5px);
  }
  
  .feature i {
    font-size: 3rem;
    color: #4CAF50;
    margin-bottom: 1rem;
  }
  
  .feature h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .feature p {
    color: #666;
  }
  
  .how-it-works {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .how-it-works h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    perspective: 1000px;
    gap: 2rem;
  }
  
  .step {
    flex-basis: calc(33.333% - 2rem);
    max-width: 300px;
    margin: 1rem;
    perspective: 1000px;
    transition: transform 0.3s ease;
    transform-style: preserve-3d;
  }
  
  .step-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    transition: all 0.3s ease;
    transform-style: preserve-3d;
    position: relative;
    z-index: 1;
  }
  
  .step:hover .step-content {
    transform: scale(1.3);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  
  .image-container {
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  
  .step-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .step h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .step p {
    color: #666;
  }
  
  @media (max-width: 1200px) {
    .step {
      flex-basis: calc(50% - 2rem);
    }
  }
  
  @media (max-width: 768px) {
    .step {
      flex-basis: 100%;
      max-width: 100%;
    }
  
    .step:hover .step-content {
      transform: scale(1.1);
    }
  }
  </style>