import { createApp } from 'vue'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import i18n from './i18n'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import LoaderMain from './components/Loader.vue'

// Remove the import for configureCompat

const app = createApp(App)
app.component('LoaderMain', LoaderMain)
app.use(store)
app.use(router)
app.use(i18n)
app.use(Toast, {
  // You can set your default options here
})
app.use(autoAnimatePlugin)

app.config.globalProperties.$http = axios.create({
  baseURL: 'http://localhost:5000/api'  // Backend API URL
})

// Add this to suppress the hydration mismatch warning in development
if (process.env.NODE_ENV === 'development') {
  app.config.performance = true
  app.config.devtools = true
  app.config.warnHandler = (msg, instance, trace) => {
    if (msg.includes('Hydration node mismatch')) {
      // Suppress the warning
      return
    }
    // Log other warnings as usual
    console.warn(msg, instance, trace)
  }
}

app.mount('#app')
