<template>
  <div class="menu-preview-container">
    <div class="menu-preview">
      <h1 class="text-center mb-4">{{ t('menuPreview') }}</h1>
      
      <div class="action-bar mb-4">
        <router-link :to="{ name: 'MenuCreator', query: { menuData: encodedMenuData } }" class="btn btn-outline-secondary">
          <Icon icon="mdi:arrow-left" /> {{ t('backToEdit') }}
        </router-link>
        <div class="action-buttons">
          <button v-if="!hasEnglishVersion" @click="translateMenu" class="btn btn-primary" :disabled="isTranslating">
            <Icon icon="mdi:translate" /> {{ t('translateMenu') }}
          </button>
          <button @click="saveMenu" class="btn btn-success">
            <Icon icon="mdi:content-save" /> {{ t('saveMenu') }}
          </button>
        </div>
        <div class="token-display">
          <Icon icon="mdi:coin" />
          <span>{{ t('tokensRemaining') }}: {{ userTokens }}</span>
        </div>
      </div>

      <div class="menu-cards">
        <div class="menu-card bulgarian">
          <div class="card-header">
            <h2>{{ t('bulgarianMenu') }}</h2>
          </div>
          <div class="card-body">
            <div ref="bulgarianQuillEditor"></div>
          </div>
          <div class="card-footer">
            <button @click="printMenu('bulgarian')" class="btn btn-sm btn-outline-primary">
              <Icon icon="mdi:printer" /> {{ t('print') }}
            </button>
            <button @click="saveAsPDF('bulgarian')" class="btn btn-sm btn-outline-secondary">
              <Icon icon="mdi:file-pdf-box" /> {{ t('savePDF') }}
            </button>
            <button @click="generateQRCode('bulgarian')" class="btn btn-sm btn-outline-info">
              <Icon icon="mdi:qrcode" /> {{ t('qrCode') }}
            </button>
          </div>
        </div>

        <div class="menu-card english">
          <div class="card-header">
            <h2>{{ t('englishMenu') }}</h2>
          </div>
          <div class="card-body">
            <div v-if="isTranslating" class="translating-overlay">
              <LoaderMain />
            </div>
            <div v-show="isTranslated" ref="englishQuillEditor"></div>
            <div v-if="!isTranslated && !isTranslating" class="translate-prompt">
              <p>{{ t('clickTranslateToSeeEnglishMenu') }}</p>
            </div>
          </div>
          <div class="card-footer" v-if="isTranslated">
            <button @click="printMenu('english')" class="btn btn-sm btn-outline-primary">
              <Icon icon="mdi:printer" /> {{ t('print') }}
            </button>
            <button @click="saveAsPDF('english')" class="btn btn-sm btn-outline-secondary">
              <Icon icon="mdi:file-pdf-box" /> {{ t('savePDF') }}
            </button>
            <button @click="generateQRCode('english')" class="btn btn-sm btn-outline-info">
              <Icon icon="mdi:qrcode" /> {{ t('qrCode') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <ConfirmationModal
      ref="confirmationModal"
      :title="modalConfig.title"
      :message="modalConfig.message"
      :showInput="modalConfig.showInput"
      :inputLabel="modalConfig.inputLabel"
      :confirmButtonText="modalConfig.confirmButtonText"
      :confirmButtonClass="modalConfig.confirmButtonClass"
      :cancelButtonText="modalConfig.cancelButtonText"
      @confirm="handleModalConfirm"
    />
    <!-- QR Code Modal -->
    <div class="modal fade" id="qrCodeModal" tabindex="-1" aria-labelledby="qrCodeModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="qrCodeModalLabel">{{ t('qrCodeForMenu') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <qrcode-vue :value="qrCodeValue" :size="200" level="H"></qrcode-vue>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, onUnmounted, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import html2pdf from 'html2pdf.js'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'
import axios from '../api/axios'
import { Icon } from '@iconify/vue'
import { useRouter } from 'vue-router'
import { useToast } from "vue-toastification"
import ConfirmationModal from './ConfirmationModal.vue'
import QrcodeVue from 'qrcode.vue'
import { Modal } from 'bootstrap'  // Import Bootstrap's Modal
import { useStore } from 'vuex'
import LoaderMain from './Loader.vue'

export default {
  components: {
    Icon,
    ConfirmationModal,
    QrcodeVue,
    LoaderMain
  },
  setup() {
    const { t, locale } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()
    const store = useStore()

    const bulgarianQuillEditor = ref(null)
    const englishQuillEditor = ref(null)
    let bulgarianQuill = null
    let englishQuill = null

    const bulgarianMenuHTML = ref('')
    const englishMenuHTML = ref('')
    const menuDataObj = ref(JSON.parse(decodeURIComponent(route.query.menuData || '[]')))
    const savedMenu = ref(null)
    const isTranslating = ref(false)
    const isTranslated = ref(false)
    const hasEnglishVersion = ref(false)
    const userTokens = ref(null)
    let tokenRefreshInterval

    const confirmationModal = ref(null)
    const modalConfig = ref({
      title: '',
      message: '',
      showInput: false,
      inputLabel: '',
      confirmButtonText: '',
      confirmButtonClass: '',
      cancelButtonText: ''
    })
    const currentAction = ref('')

    const qrCodeValue = ref('')

    const fetchUserTokens = async () => {
      try {
        const response = await axios.get('/api/user/tokens', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        userTokens.value = response.data.tokens
      } catch (error) {
        console.error('Error fetching user tokens:', error)
      }
    }

    onMounted(() => {
      const menuDataParam = route.query.menuData
      const savedMenuParam = route.query.savedMenu
      const hasEnglishVersionParam = route.query.hasEnglishVersion

      if (menuDataParam) {
        menuDataObj.value = JSON.parse(decodeURIComponent(menuDataParam))
      }

      if (savedMenuParam) {
        savedMenu.value = JSON.parse(decodeURIComponent(savedMenuParam))
        if (savedMenu.value.englishMenu) {
          hasEnglishVersion.value = true
          isTranslated.value = true
        }
      }

      if (hasEnglishVersionParam === 'true') {
        hasEnglishVersion.value = true
        isTranslated.value = true
      }

      fetchUserTokens()
      tokenRefreshInterval = setInterval(fetchUserTokens, 10000)

      nextTick(() => {
        initQuillEditors()
        renderBulgarianMenu()
        if (isTranslated.value && savedMenu.value && savedMenu.value.englishMenu) {
          renderEnglishMenu(savedMenu.value.englishMenu)
        }
      })

      onUnmounted(() => {
        // Clear the stored Quill content when leaving the preview page
        store.commit('setMenuContent', null)
      })
    })

    onUnmounted(() => {
      clearInterval(tokenRefreshInterval)
    })

    const initQuillEditors = () => {
      const options = {
        readOnly: true,
        modules: {
          toolbar: false
        },
        theme: 'snow'
      }
      
      if (bulgarianQuillEditor.value) {
        bulgarianQuill = new Quill(bulgarianQuillEditor.value, options)
        console.log('Bulgarian Quill initialized')
        renderBulgarianMenu()
      }
      
      if (englishQuillEditor.value) {
        englishQuill = new Quill(englishQuillEditor.value, options)
        console.log('English Quill initialized')
        if (isTranslated.value && savedMenu.value && savedMenu.value.englishMenu) {
          renderEnglishMenu(savedMenu.value.englishMenu)
        }
      }
    }

    const updateQuillContent = (content) => {
      store.commit('setMenuContent', content)
    }

    const renderBulgarianMenu = () => {
      if (!bulgarianQuill) return

      const storedContent = store.state.menuContent
      
      if (storedContent) {
        bulgarianQuill.setContents(storedContent)
      } else {
        let content = []
        menuDataObj.value.forEach((category) => {
          if (category.visible) {
            content.push({ insert: `${t(category.name, { locale: 'bg' })}\n`, attributes: { header: 2 } })
            if (category.dishes.length > 0) {
              category.dishes.forEach(dish => {
                content.push({ insert: `• ${dish.name} - ${dish.price} BGN\n` })
                if (dish.description) {
                  content.push({ insert: `  ${dish.description}\n` })
                }
                if (dish.allergens.length) {
                  content.push({ insert: `  ${t('allergens', { locale: 'bg' })}: ${dish.allergens.join(', ')}\n` })
                }
              })
            } else {
              content.push({ insert: `${t('noDishes', { locale: 'bg' })}\n` })
            }
            content.push({ insert: '\n' })
          }
        })
        bulgarianQuill.setContents(content)
        updateQuillContent(content)  // Update the store with the new content
      }
    }

    const translateMenu = async () => {
      if (userTokens.value <= 0) {
        showModal({
          title: t('outOfTokens'),
          message: t('buyMoreTokensMessage'),
          showInput: false,
          confirmButtonText: t('buyTokens'),
          confirmButtonClass: 'btn btn-primary',
          cancelButtonText: t('cancel')
        }, 'buyTokens')
        return
      }

      console.log('Menu Data before translation:', menuDataObj.value)
      isTranslating.value = true
      try {
        const translatedMenuData = await Promise.all(
          menuDataObj.value.map(async category => ({
            ...category,
            dishes: await Promise.all(category.dishes.map(async dish => {
              const nameEn = await translateDish(dish.name, category.name, dish.description)
              const descriptionEn = dish.description ? await translateDish(dish.description, category.name, '') : ''
              return {
                ...dish,
                nameEn,
                descriptionEn
              }
            }))
          }))
        )

        console.log('Translated Menu Data:', translatedMenuData)
        menuDataObj.value = translatedMenuData  // Update menuDataObj with translated data
        
        isTranslated.value = true;
        console.log('isTranslated set to true');

        await nextTick();  // Wait for the DOM to update

        console.log('About to initialize English Quill editor');
        if (!englishQuill && englishQuillEditor.value) {
          const options = {
            readOnly: true,
            modules: {
              toolbar: false
            },
            theme: 'snow'
          };
          englishQuill = new Quill(englishQuillEditor.value, options);
          console.log('English Quill editor initialized');
        } else if (!englishQuillEditor.value) {
          console.error('English Quill editor DOM element not found');
        }

        console.log('About to render English menu');
        renderEnglishMenu(translatedMenuData);
        console.log('English menu rendered');
      } catch (error) {
        console.error('Translation error:', error)
        // Handle error (e.g., show error message to user)
      } finally {
        isTranslating.value = false
      }
    }

    const translateDish = async (dish, category, description) => {
      try {
        const response = await axios.post('/api/translate', {
          bulgarianDish: dish,
          category: category,
          description: description
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        console.log('Translation response:', response.data)
        return response.data.nameEn || dish // Return nameEn if it exists, otherwise return the original dish
      } catch (error) {
        console.error('Error translating dish:', error)
        return dish // Return original dish name if translation fails
      }
    }

    const renderEnglishMenu = (translatedMenuData) => {
      console.log('Entering renderEnglishMenu function');
      if (!englishQuill) {
        console.error('English Quill editor not initialized');
        if (englishQuillEditor.value) {
          const options = {
            readOnly: true,
            modules: {
              toolbar: false
            },
            theme: 'snow'
          };
          englishQuill = new Quill(englishQuillEditor.value, options);
          console.log('English Quill editor initialized in renderEnglishMenu');
        } else {
          console.error('English Quill editor DOM element still not found');
          return;
        }
      }

      console.log('Rendering English Menu:', JSON.parse(JSON.stringify(translatedMenuData)));
      let content = []
      translatedMenuData.forEach((category) => {
        if (category.visible) {
          // Force English translation for category names
          let englishCategoryName;
          switch(category.name) {
            case 'starters':
              englishCategoryName = 'Starters';
              break;
            case 'mainCourses':
              englishCategoryName = 'Main Courses';
              break;
            case 'desserts':
              englishCategoryName = 'Desserts';
              break;
            case 'drinks':
              englishCategoryName = 'Drinks';
              break;
            default:
              englishCategoryName = category.name;
          }
          console.log(`Category: ${category.name}, Translated: ${englishCategoryName}`)
          console.log(`Adding category: ${englishCategoryName}`)
          content.push({ insert: `${englishCategoryName}\n`, attributes: { header: 2 } })
          if (category.dishes.length > 0) {
            category.dishes.forEach(dish => {
              console.log('Adding dish:', dish)
              content.push({ insert: `• ${dish.nameEn || dish.name} - ${dish.price} BGN\n` })
              if (dish.descriptionEn) {
                content.push({ insert: `  ${dish.descriptionEn}\n` })
              }
              if (dish.allergens.length) {
                const translatedAllergens = dish.allergens.map(allergen => {
                  // Force English translation for allergens
                  return {
                    gluten: 'Gluten',
                    crustaceans: 'Crustaceans',
                    eggs: 'Eggs',
                    fish: 'Fish',
                    peanuts: 'Peanuts',
                    soybeans: 'Soybeans',
                    milk: 'Milk',
                    nuts: 'Nuts',
                    celery: 'Celery',
                    mustard: 'Mustard',
                    sesame: 'Sesame',
                    sulphites: 'Sulphites',
                    lupin: 'Lupin',
                    molluscs: 'Molluscs'
                  }[allergen] || allergen;
                }).join(', ')
                content.push({ insert: `  Allergens: ${translatedAllergens}\n` })
              }
            })
          } else {
            console.log('No dishes in category')
            content.push({ insert: `No dishes in this category\n` })
          }
          content.push({ insert: '\n' })
        }
      })
      console.log('Final content for English Quill:', content)
      englishQuill.setContents(content)
      updateQuillContent(content)
      console.log('English Quill contents set')
    }

    const encodedMenuData = computed(() => {
  const dataToEncode = {
    categories: menuDataObj.value,
    quillContent: store.state.menuContent
  }
  return encodeURIComponent(JSON.stringify(dataToEncode))
})

    const printMenu = (language) => {
      const quill = language === 'bulgarian' ? bulgarianQuill : englishQuill
      if (!quill) return

      const printWindow = window.open('', '_blank')
      printWindow.document.write(`
        <html>
          <head>
            <title>${t(`${language}Menu`)}</title>
            <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
            <style>
              body { font-family: Arial, sans-serif; }
              h2 { color: #333; }
              .ql-editor { padding: 0; }
            </style>
          </head>
          <body>
            <div class="ql-container ql-snow" style="border: none;">
              <div class="ql-editor">
                ${quill.root.innerHTML}
              </div>
            </div>
          </body>
        </html>
      `)
      printWindow.document.close()
      printWindow.print()
    }

    const saveAsPDF = (language) => {
      const quill = language === 'bulgarian' ? bulgarianQuill : englishQuill
      if (!quill) return

      const element = document.createElement('div')
      element.innerHTML = `
        <div class="ql-container ql-snow" style="border: none;">
          <div class="ql-editor">
            ${quill.root.innerHTML}
          </div>
        </div>
      `

      const opt = {
        margin:       [10, 10],
        filename:     `${language}-menu.pdf`,
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }

      // Include Quill CSS
      const style = document.createElement('style')
      style.textContent = `
        @import url('https://cdn.quilljs.com/1.3.6/quill.snow.css');
        .ql-editor { padding: 0; }
      `
      document.head.appendChild(style)

      // Generate PDF
      html2pdf().from(element).set(opt).save().then(() => {
        document.head.removeChild(style)
      })
    }

    const showModal = (config, action) => {
      modalConfig.value = {
        title: config.title || '',
        message: config.message || '',
        showInput: config.showInput || false,
        inputLabel: config.inputLabel || '',
        confirmButtonText: config.confirmButtonText || '',
        confirmButtonClass: config.confirmButtonClass || '',
        cancelButtonText: config.cancelButtonText || ''
      }
      currentAction.value = action
      confirmationModal.value.show()
    }

    const saveMenu = () => {
      showModal({
        title: t('saveMenu'),
        message: t('enterMenuName'),
        showInput: true,
        inputLabel: t('menuName'),
        confirmButtonText: t('save'),
        confirmButtonClass: 'btn btn-primary'
      }, 'save')
    }

    const handleModalConfirm = async (value) => {
      if (currentAction.value === 'save') {
        await handleSaveMenu(value)
      } else if (currentAction.value === 'buyTokens') {
        // Redirect to token purchase page or show token purchase modal
        // For now, we'll just log a message
        console.log('Redirect to token purchase page')
        // You can implement the actual token purchase logic here
      }
    }

    const handleSaveMenu = async (menuName) => {
      try {
        const menuData = {
          name: menuName,
          bulgarianMenu: menuDataObj.value,
          englishMenu: isTranslated.value ? JSON.parse(JSON.stringify(menuDataObj.value)) : null
        }
        
        await axios.post('/api/menus/save', menuData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        
        toast.success(t('menuSaved'), {
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        })
        router.push({ name: 'MenuHistory' })
      } catch (error) {
        console.error('Error saving menu:', error)
        toast.error(t('errorSavingMenu'), {
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        })
      }
    }

    const generateQRCode = (language) => {
      const quill = language === 'bulgarian' ? bulgarianQuill : englishQuill
      if (!quill) return

      qrCodeValue.value = quill.root.innerHTML
      const modal = new Modal(document.getElementById('qrCodeModal'))
      modal.show()
    }

    watch(isTranslated, async (newValue) => {
      console.log('isTranslated changed:', newValue);
      if (newValue) {
        await nextTick();  // Wait for the DOM to update
        if (!englishQuill && englishQuillEditor.value) {
          const options = {
            readOnly: true,
            modules: {
              toolbar: false
            },
            theme: 'snow'
          };
          englishQuill = new Quill(englishQuillEditor.value, options);
          console.log('English Quill editor initialized in watch');
        }
        if (englishQuill) {
          renderEnglishMenu(menuDataObj.value);
        }
      }
    })

    return {
      t,
      locale,
      bulgarianMenuHTML,
      englishMenuHTML,
      printMenu,
      saveAsPDF,
      encodedMenuData,
      translateMenu,
      isTranslating,
      isTranslated,
      saveMenu,
      menuDataObj,
      savedMenu,
      hasEnglishVersion,
      confirmationModal,
      modalConfig,
      handleModalConfirm,
      userTokens,
      fetchUserTokens,
      generateQRCode,
      qrCodeValue,
      bulgarianQuillEditor,
      englishQuillEditor,
      updateQuillContent
    }
  }
}
</script>

<style scoped>
.menu-preview-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.menu-preview {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.token-display {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
  color: #6c757d;
}

.menu-cards {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.menu-card {
  flex: 1;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card-header {
  background-color: #007bff;
  color: white;
  padding: 1rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.card-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.card-body {
  flex-grow: 1;
  padding: 1.5rem;
  overflow-y: auto;
  position: relative;
}

.card-footer {
  padding: 1rem;
  background-color: #f1f3f5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

</style>

