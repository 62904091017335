import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/Home.vue'
import Login from './components/Login.vue'
import Register from './components/Register.vue'
import CreateMenu from './components/CreateMenu.vue'
import MenuCreator from './components/MenuCreator.vue'
import MenuPreview from './components/MenuPreview.vue'
import TranslateMenu from './components/TranslateMenu.vue'
import MenuHistory from './components/MenuHistory.vue'
import AdminPanel from './components/AdminPanel.vue'
import AccountPage from './components/AccountPage.vue'

const routes = [
  { 
    path: '/', 
    name: 'Home',  // Add this name
    component: HomePage
  },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { 
    path: '/create', 
    name: 'CreateMenu',
    component: CreateMenu,
    meta: { requiresAuth: true }
  },
  { 
    path: '/menu-creator', 
    name: 'MenuCreator',
    component: MenuCreator,
    meta: { requiresAuth: true }
  },
  { 
    path: '/preview',
    name: 'MenuPreview',
    component: MenuPreview,
    meta: { requiresAuth: true },
    props: route => ({ menuData: route.query.menuData })
  },
  { 
    path: '/translate',
    name: 'TranslateMenu',
    component: TranslateMenu,
    meta: { requiresAuth: true },
    props: route => ({ menuData: route.query.menuData })
  },
  { 
    path: '/menu-history',
    name: 'MenuHistory',
    component: MenuHistory,
    meta: { requiresAuth: true }
  },
  { 
    path: '/admin',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  { 
    path: '/account',
    name: 'Account',
    component: AccountPage,
    meta: { requiresAuth: true }
  },
  // Add other routes as needed
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('token')
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next('/login')
    } else if (to.matched.some(record => record.meta.requiresAdmin)) {
      if (isUserAdmin()) {
        next()
      } else {
        next('/')
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

// You'll need to implement this function
function isUserAdmin() {
  // Check if the user is an admin (e.g., by checking a flag in localStorage)
  return localStorage.getItem('isAdmin') === 'true'
}

export default router
