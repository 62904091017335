<template>
  <div>
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h2 class="card-title text-center mb-4">{{ $t('register') }}</h2>
              <form @submit.prevent="registerUser">
                <div class="mb-3">
                  <label for="username" class="form-label">{{ $t('username') }}:</label>
                  <input id="username" v-model="username" class="form-control" required />
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label">{{ $t('email') }}:</label>
                  <input id="email" type="email" v-model="email" class="form-control" required />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">{{ $t('password') }}:</label>
                  <input id="password" type="password" v-model="password" class="form-control" required />
                </div>
                <button type="submit" class="btn btn-primary w-100">{{ $t('register') }}</button>
              </form>
              <p class="text-center mt-3">
                {{ $t('alreadyHaveAccount') }} 
                <router-link to="/login">{{ $t('loginHere') }}</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from '../api/axios';  // Import the axios instance

export default {
  name: 'RegisterForm',
  components: {
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      username: '',
      email: '',
      password: ''
    }
  },
  methods: {
    async registerUser() {
      try {
        await axios.post('/api/register', {
          username: this.username,
          email: this.email,
          password: this.password
        })
        this.toast.success(this.$t('registrationSuccess'));
        this.$router.push('/login')
      } catch (error) {
        this.toast.error(this.$t('registrationFailed'));
      }
    }
  }
}
</script>