import { createStore } from 'vuex'

export default createStore({
  state: {
    menuContent: {
      bulgarian: null,
      english: null
    },
  },
  mutations: {
    setMenuContent(state, content) {
      state.menuContent = content
    },
  },
  actions: {
    updateMenuContent({ commit }, content) {
      commit('setMenuContent', content)
    },
  },
  getters: {
    getMenuContent: (state) => state.menuContent,
  },
})