<template>
  <div id="app">
    <AppHeader />
    <main class="main-content">
      <router-view></router-view>
    </main>
    <!-- Footer if you have one -->
  </div>
</template>

<script>
import AppHeader from './components/Header.vue'

export default {
  name: 'App',
  components: {
    AppHeader
  }
}
</script>

<style>
body {
  background-color: #f8f9fa;
}

.container {
  max-width: 1200px;
}

.main-content {
  padding-top: 60px; /* Adjust this value based on your header's height */
}
</style>