<template>
  <div class="admin-panel">
    <h1>{{ t('adminPanel') }}</h1>
    
    <!-- Add User Form -->
    <form @submit.prevent="addUser" class="mb-4">
      <input v-model="newUser.username" :placeholder="t('username')" required>
      <input v-model="newUser.password" type="password" :placeholder="t('password')" required>
      <input v-model.number="newUser.translation_tokens" type="number" :placeholder="t('translationTokens')" required>
      <label>
        <input v-model="newUser.is_admin" type="checkbox">
        {{ t('isAdmin') }}
      </label>
      <button type="submit" class="btn btn-success">{{ t('addUser') }}</button>
    </form>

    <!-- Users Table -->
    <table class="table">
      <thead>
        <tr>
          <th>{{ t('username') }}</th>
          <th>{{ t('isAdmin') }}</th>
          <th>{{ t('translationTokens') }}</th>
          <th>{{ t('actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user._id">
          <td>{{ user.username }}</td>
          <td>{{ user.is_admin ? t('yes') : t('no') }}</td>
          <td>
            <input v-if="editingUser === user._id" v-model.number="user.translation_tokens" type="number">
            <span v-else>{{ user.translation_tokens }}</span>
          </td>
          <td>
            <button v-if="editingUser === user._id" @click="saveUser(user)" class="btn btn-sm btn-success">{{ t('save') }}</button>
            <button v-else @click="editUser(user._id)" class="btn btn-sm btn-primary">{{ t('edit') }}</button>
            <button @click="deleteUser(user._id)" class="btn btn-sm btn-danger">{{ t('delete') }}</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Message display -->
    <div v-if="message" :class="['alert', message.type === 'error' ? 'alert-danger' : 'alert-success']">
      {{ message.text }}
    </div>
  </div>
</template>

<script>
import axios from '../api/axios';  // Make sure this import is correct
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AdminPanel',
  setup() {
    const { t } = useI18n()
    const users = ref([])
    const editingUser = ref(null)
    const message = ref(null)
    const newUser = ref({
      username: '',
      password: '',
      is_admin: false,
      translation_tokens: 10
    })

    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/admin/users')
        users.value = response.data
      } catch (error) {
        console.error('Error fetching users:', error)
        showMessage(t('errorFetchingUsers'), 'error')
      }
    }

    const editUser = (userId) => {
      editingUser.value = userId
    }

    const saveUser = async (user) => {
      try {
        await axios.put(`/api/admin/users`, user)
        editingUser.value = null
        showMessage('User updated successfully')
        await fetchUsers()
      } catch (error) {
        console.error('Error updating user:', error)
        showMessage('Error updating user', 'error')
      }
    }

    const deleteUser = async (userId) => {
      if (confirm(t('confirmDelete'))) {
        try {
          await axios.delete(`/api/admin/users?id=${userId}`)
          showMessage('User deleted successfully')
          await fetchUsers()
        } catch (error) {
          console.error('Error deleting user:', error)
          showMessage('Error deleting user', 'error')
        }
      }
    }

    const addUser = async () => {
      try {
        await axios.post('/api/admin/users', newUser.value)
        showMessage('User added successfully')
        newUser.value = { username: '', password: '', is_admin: false, translation_tokens: 10 }
        await fetchUsers()
      } catch (error) {
        console.error('Error adding user:', error)
        showMessage('Error adding user', 'error')
      }
    }

    const showMessage = (text, type = 'success') => {
      message.value = { text, type }
      setTimeout(() => {
        message.value = null
      }, 3000)
    }

    onMounted(fetchUsers)

    return {
      t,
      users,
      editingUser,
      message,
      newUser,
      editUser,
      saveUser,
      deleteUser,
      addUser
    }
  }
}
</script>

<style scoped>
.admin-panel {
  max-width: 800px;
  margin: 0 auto;
}

form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

input {
  padding: 5px;
}

.alert {
  margin-top: 20px;
}
</style>