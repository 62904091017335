import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    appName: 'Lunch Menu Creator',
    welcome: 'Welcome to the Lunch Menu Creator',
    login: 'Login',
    logout: 'Logout',
    register: 'Register',
    createMenu: 'Create a New Menu',
    dishName: 'Dish Name',
    description: 'Description',
    menuEditor: 'Menu Editor',
    menuPreview: 'Menu Preview',
    newCategory: 'New Category',
    addCategory: 'Add Category',
    newDish: 'New Dish',
    addDish: 'Add Dish',
    starters: 'Starters',
    mainCourses: 'Main Courses',
    desserts: 'Desserts',
    drinks: 'Drinks',
    hide: 'Hide',
    show: 'Show',
    remove: 'Remove',
    noDishes: 'No dishes in this category',
    home: 'Home',
    menuCreator: 'Menu Creator',
    username: 'Username',
    password: 'Password',
    noAccount: 'Don\'t have an account?',
    registerHere: 'Register here',
    alreadyHaveAccount: 'Already have an account?',
    loginHere: 'Login here',
    loginSuccess: 'Login successful',
    loginFailed: 'Login failed. Please check your credentials and try again.',
    // New translations
    previewMenu: 'Preview Menu',
    bulgarianMenu: 'Bulgarian Menu',
    englishMenu: 'English Menu',
    englishTranslationComingSoon: 'English translation coming soon',
    printMenu: 'Print Menu',
    saveAsPDF: 'Save as PDF',
    close: 'Close',
    printBulgarianMenu: 'Print Bulgarian Menu',
    saveBulgarianAsPDF: 'Save Bulgarian as PDF',
    printEnglishMenu: 'Print English Menu',
    saveEnglishAsPDF: 'Save English as PDF',
    edit: 'Edit',
    save: 'Save',
    cancel: 'Cancel',
    price: 'Price',
    allergens: 'Allergens',
    // Allergen names
    gluten: 'Gluten',
    crustaceans: 'Crustaceans',
    eggs: 'Eggs',
    fish: 'Fish',
    peanuts: 'Peanuts',
    soybeans: 'Soybeans',
    milk: 'Milk',
    nuts: 'Nuts',
    celery: 'Celery',
    mustard: 'Mustard',
    sesame: 'Sesame',
    sulphites: 'Sulphites',
    lupin: 'Lupin',
    molluscs: 'Molluscs',
    clickTranslateToSeeEnglishMenu: 'Click "Translate Menu" to see the English version',
    registrationSuccess: 'Registration successful',
    registrationFailed: 'Registration failed',
    translateMenu: 'Translate Menu',
    backToEdit: 'Back to Edit',
    loading: 'Loading',
    translating: 'Translating...',
    saveMenu: 'Save Menu',
    menuHistory: 'Menu History',
    hasEnglishVersion: 'Has English Version',
    yes: 'Yes',
    no: 'No',
    viewMenu: 'View Menu',
    categories: 'Categories',
    totalDishes: 'Total Dishes',
    enterMenuName: 'Enter menu name',
    menuSaved: 'Menu saved successfully',
    errorSavingMenu: 'Error saving menu',
    deleteMenu: 'Delete Menu',
    confirmDeleteMenu: 'Are you sure you want to delete this menu?',
    menuDeleted: 'Menu deleted successfully',
    errorDeletingMenu: 'Error deleting menu',
    delete:'Delete',
    adminPanel: 'Admin Panel',
    tokens: 'Tokens',
    translationTokens: 'Translation Tokens',
    isAdmin: 'Is Admin',
    addUser: 'Add User',
    actions: 'Actions',
    errorFetchingUsers: 'Error fetching users',
    tokensRemaining: "Tokens Remaining",
    outOfTokens: "Out of Tokens",
    buyMoreTokensMessage: "You're out of translation tokens. Would you like to buy more?",
    buyTokens: "Buy Tokens",
    generateQRCode: 'Generate QR Code',
    qrCodeForMenu: 'QR Code for Menu',
    scanToViewMenu: 'Scan to view menu',
    print: 'Print',
    savePDF: 'Save as PDF',
    qrCode: 'QR Code',
    // New translations for the home page
    welcomeTitle: 'Welcome to Lunch Menu Creator',
    welcomeDescription: 'Create, manage, and translate your restaurant menus with ease.',
    getStarted: 'Get Started',
    featuresTitle: 'Our Features',
    feature1Title: 'Easy Menu Creation',
    feature1Description: 'Create beautiful menus in minutes with our intuitive interface.',
    feature2Title: 'Instant Translation',
    feature2Description: 'Translate your menus to English with just one click.',
    feature3Title: 'Menu History',
    feature3Description: 'Access and manage all your past menus effortlessly.',
    howItWorksTitle: 'How It Works',
    step1Title: 'Create Your Menu',
    step1Description: 'Easily add dishes and categories to build your perfect menu.',
    step2Title: 'Modify the content to your hearts content',
    step2Description: 'Using our rich text editor you can modify the content of your menu as you like.',
    step3Title: 'Translate Instantly, Share and Print',
    step3Description: 'Translate to the language you want, generate QR codes, print your menu, or share it digitally.',
    account: 'Account',
    usernameOrEmail: 'Username or Email',
    changeEmail: 'Change Email',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm New Password',
    emailChanged: 'Email changed successfully',
    passwordChanged: 'Password changed successfully',
    errorChangingEmail: 'Error changing email',
    errorChangingPassword: 'Error changing password',
    newEmail: 'New Email',
  },
  bg: {
    appName: 'Създател на меню за обяд',
    welcome: 'Добре дошли в Създателя на меню за обяд',
    login: 'Вход',
    logout: 'Изход',
    register: 'Регистрация',
    createMenu: 'Създайте ново меню',
    dishName: 'Име на ястие',
    description: 'Описание',
    menuEditor: 'Редактор на менюто',
    menuPreview: 'Преглед на менюто',
    newCategory: 'Нова категория',
    addCategory: 'Добави категория',
    newDish: 'Ново ястие',
    addDish: 'Добави ястие',
    starters: 'Предястия',
    mainCourses: 'Основни ястия',
    desserts: 'Десерти',
    drinks: 'Напитки',
    hide: 'Скрий',
    show: 'Покажи',
    remove: 'Премахни',
    noDishes: 'Няма ястия в тази категория',
    home: 'Начало',
    menuCreator: 'Създател на меню',
    username: 'Потребителско име',
    password: 'Парола',
    noAccount: 'Нямате акаунт?',
    registerHere: 'Регистрирайте се тук',
    alreadyHaveAccount: 'Вече имате акаунт?',
    loginHere: 'Влезте тук',
    loginSuccess: 'Успешно влизане',
    loginFailed: 'Неуспешно влизане. Моля, проверете данните си и опитайте отново.',
    // New translations
    previewMenu: 'Преглед на менюто',
    bulgarianMenu: 'Българско меню',
    englishMenu: 'Английско меню',
    englishTranslationComingSoon: 'Английският превод предстои скоро',
    printMenu: 'Отпечатай меню',
    saveAsPDF: 'Запази като PDF',
    close: 'Затвори',
    printBulgarianMenu: 'Отпечатай българското меню',
    saveBulgarianAsPDF: 'Запази българското меню като PDF',
    printEnglishMenu: 'Отпечатай английското меню',
    saveEnglishAsPDF: 'Запази английското меню като PDF',
    edit: 'Редактиране',
    save: 'Запази',
    cancel: 'Отказ',
    price: 'Цена',
    allergens: 'Алергени',
    // Allergen names
    gluten: 'Глутен',
    crustaceans: 'Ракообразни',
    eggs: 'Яйца',
    fish: 'Риба',
    peanuts: 'Фъстъци',
    soybeans: 'Соя',
    milk: 'Мляко',
    nuts: 'Ядки',
    celery: 'Целина',
    mustard: 'Горчица',
    sesame: 'Сусам',
    sulphites: 'Сулфити',
    lupin: 'Лупина',
    molluscs: 'Мекотели',
    clickTranslateToSeeEnglishMenu: 'Натиснете "Преведи менюто" за да видите английската версия',
    registrationSuccess: 'Регистрацията е успешна',
    registrationFailed: 'Регистрацията не бе успешна',
    translateMenu: 'Преведи менюто',
    backToEdit: 'Обратно към редактиране',
    loading: 'Зареждане',
    translating: 'Превежда се...',
    saveMenu: 'Запази меню',
    menuHistory: 'История на менютата',
    hasEnglishVersion: 'Има английска версия',
    yes: 'Да',
    no: 'Не',
    viewMenu: 'Преглед на меню',
    categories: 'Категории',
    totalDishes: 'Общо ястия',
    enterMenuName: 'Въведете име на менюто',
    menuSaved: 'Менюто е запазено успешно',
    errorSavingMenu: 'Грешка при запазване на менюто',
    deleteMenu: 'Изтрий меню',
    confirmDeleteMenu: 'Сигурни ли сте, че искате да изтриете това меню?',
    menuDeleted: 'Менюто е изтрито успешно',
    errorDeletingMenu: 'Грешка при изтриване на менюто',
    delete:'Изтрий',
    adminPanel: 'Админ Панел',
    tokens: 'Токени',
    tokensRemaining: 'Токени Останали',
    translationTokens: 'Токени за превод',
    isAdmin: 'Е администратор',
    addUser: 'Добави потребител',
    actions: 'Действия',
    errorFetchingUsers: 'Грешка при извличане на потребителите',
    generateQRCode: 'Генерирай QR код',
    qrCodeForMenu: 'QR код за меню',
    scanToViewMenu: 'Сканирайте, за да видите менюто',
    print: 'Печат',
    savePDF: 'Запази като PDF',
    qrCode: 'QR Код',
    // New translations for the home page
    welcomeTitle: 'Добре дошли в Създателя на меню за обяд',
    welcomeDescription: 'Създавайте, управлявайте и превеждайте менютата на вашия ресторант с лекота.',
    getStarted: 'Започнете',
    featuresTitle: 'Нашите функции',
    feature1Title: 'Лесно създаване на меню',
    feature1Description: 'Създайте красиви менюта за минути с нашия интуитивен интерфейс.',
    feature2Title: 'Моментален превод',
    feature2Description: 'Преведете вашите менюта на английски само с един клик.',
    feature3Title: 'История на менютата',
    feature3Description: 'Достъпвайте и управлявайте всичките си минали менюта без усилие.',
    howItWorksTitle: 'Как работи',
    step1Title: 'Създайте вашето меню',
    step1Description: 'Лесно добавяйте ястия и категории, за да изградите перфектното си меню.',
    step2Title: 'Променяйте съдържанието според желанието си',
    step2Description: 'Използвайки нашия богат текстов редактор, можете да модифицирате съдържанието на менюто си както искате.',
    step3Title: 'Преведете мигновено, споделете и отпечатайте',
    step3Description: 'Преведете на желания от вас език, генерирайте QR кодове, отпечатайте менюто си или го споделете дигитално.',
    account: 'Акаунт',
    usernameOrEmail: 'Потребителско име или имейл',
    changeEmail: 'Промяна на имейл',
    changePassword: 'Промяна на парола',
    currentPassword: 'Текуща парола',
    newPassword: 'Нова парола',
    confirmPassword: 'Потвърди новата парола',
    emailChanged: 'Имейлът е променен успешно',
    passwordChanged: 'Паролата е променена успешно',
    errorChangingEmail: 'Грешка при промяна на имейла',
    errorChangingPassword: 'Грешка при промяна на паролата',
    newEmail: 'Нов имейл',
  }
}

const i18n = createI18n({
  legacy: false, // Set to false to use Composition API
  locale: 'bg', // Default language
  fallbackLocale: 'en',
  messages
})

export default i18n
