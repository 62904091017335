<template>
  <div>
    <div class="menu-history container-fluid">
      <h1 class="text-center mb-4"><i class="fas fa-history"></i> {{ t('menuHistory') }}</h1>
      <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ t('loading') }}</span>
        </div>
      </div>
      <div v-else-if="menus.length === 0" class="text-center">
        <p>{{ t('noSavedMenus') }}</p>
      </div>
      <div v-else class="row" v-auto-animate>
        <div v-for="menu in menus" :key="menu._id" class="col-md-6 mb-4">
          <div class="card">
            <div class="card-header">
              <h2><i class="fas fa-utensils"></i> {{ menu.name }}</h2>
            </div>
            <div class="card-body">
              <p v-if="menu.bulgarianMenu">
                <i class="fas fa-list"></i> {{ t('categories') }}: {{ getCategoryNames(menu.bulgarianMenu) }}
              </p>
              <p v-if="menu.bulgarianMenu">
                <i class="fas fa-clipboard-list"></i> {{ t('totalDishes') }}: {{ getTotalDishes(menu.bulgarianMenu) }}
              </p>
              <p>
                <i class="fas fa-language"></i> {{ t('hasEnglishVersion') }}: {{ menu.englishMenu ? t('yes') : t('no') }}
              </p>
            </div>
            <div class="card-footer d-flex justify-content-between">
              <button @click="viewMenu(menu)" class="btn btn-primary">
                <i class="fas fa-eye"></i> {{ t('viewMenu') }}
              </button>
              <button @click="deleteMenu(menu)" class="btn btn-danger">
                <i class="fas fa-trash-alt"></i> {{ t('deleteMenu') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      ref="confirmationModal"
      :title="modalConfig.title"
      :message="modalConfig.message"
      :confirmButtonText="modalConfig.confirmButtonText"
      :confirmButtonClass="modalConfig.confirmButtonClass"
      @confirm="handleModalConfirm"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import axios from '../api/axios'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { useToast } from "vue-toastification"
import ConfirmationModal from './ConfirmationModal.vue'

export default {
  components: {
    ConfirmationModal
  },
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const toast = useToast()
    const menus = ref([])
    const loading = ref(true)
    const [animateRef] = useAutoAnimate()

    const confirmationModal = ref(null)
    const modalConfig = ref({})
    const currentAction = ref('')
    const currentMenu = ref(null)

    const getCategoryNames = (bulgarianMenu) => {
      if (!Array.isArray(bulgarianMenu)) return ''
      return bulgarianMenu.map(c => t(c.name, { locale: 'bg' })).join(', ')
    }

    const getTotalDishes = (bulgarianMenu) => {
      if (!Array.isArray(bulgarianMenu)) return 0
      return bulgarianMenu.reduce((sum, c) => sum + (Array.isArray(c.dishes) ? c.dishes.length : 0), 0)
    }

    onMounted(async () => {
      await fetchMenus()
    })

    const fetchMenus = async () => {
      try {
        const response = await axios.get('/api/menus')
        menus.value = response.data.menus
      } catch (error) {
        console.error('Error fetching menus:', error)
      } finally {
        loading.value = false
      }
    }

    const viewMenu = (menu) => {
      router.push({
        name: 'MenuPreview',
        query: { 
          menuData: encodeURIComponent(JSON.stringify(menu.bulgarianMenu || [])),
          savedMenu: encodeURIComponent(JSON.stringify(menu)),
          hasEnglishVersion: menu.englishMenu ? 'true' : 'false'
        }
      })
    }

    const showModal = (config, action, menu = null) => {
      modalConfig.value = config
      currentAction.value = action
      currentMenu.value = menu
      confirmationModal.value.show()
    }

    const deleteMenu = (menu) => {
      showModal({
        title: t('deleteMenu'),
        message: t('confirmDeleteMenu', { name: menu.name }),
        confirmButtonText: t('delete'),
        confirmButtonClass: 'btn btn-danger'
      }, 'delete', menu)
    }

    const handleModalConfirm = async () => {
      if (currentAction.value === 'delete') {
        await handleDeleteMenu(currentMenu.value)
      }
      // Add other actions here as needed
    }

    const handleDeleteMenu = async (menu) => {
      try {
        await axios.delete(`/api/menus/${menu._id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        await fetchMenus() // Refresh the menu list
        toast.success(t('menuDeleted'), {
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        })
      } catch (error) {
        console.error('Error deleting menu:', error)
        toast.error(t('errorDeletingMenu'), {
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        })
      }
    }

    return { 
      t, 
      menus, 
      loading, 
      viewMenu, 
      deleteMenu,
      getCategoryNames, 
      getTotalDishes,
      animateRef,
      confirmationModal,
      modalConfig,
      handleModalConfirm
    }
  }
}
</script>

<style scoped>
.menu-history {
  padding: 20px;
}

.card {
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
</style>